// 借钥匙
<template>
  <div class="examRule p-t-30">
    <el-dialog title="" :show-close="false" :close-on-click-modal="false" :visible.sync="dialogVisible" width="1104px">
      <div class="examRule_head flex">
        <span>提交成交报告</span>
        <i class="el-icon-circle-close" @click="close"></i>
      </div>
      <div class="examRule_main p-t-30 p-r-40 p-b-30 p-l-40">
        <div class="borrow-form">
          <el-form ref="form" :rules="rules" class="form" :model="form" label-width="110px">
            <div class="sign-flex">
              <div class="item plot-input">
                <el-form-item label="小区楼栋:" prop="">
                  <el-input class="w96 m-r-10" v-model="form.hao" placeholder="几号楼"></el-input>
                  <el-input class="w96 m-r-10" v-model="form.danyuan" placeholder="几单元"></el-input>
                  <el-input class="w-96 m-r-10" v-model="form.doorplate" placeholder="门牌号"></el-input>
                  <el-input class="w96" v-model="form.direction" placeholder="朝向"></el-input>
                </el-form-item>
              </div>
              <div class="item">
                <el-form-item label="合同编号:" prop="contract_number">
                  <el-input v-model="form.contract_number" placeholder="请输入"></el-input>
                </el-form-item>
              </div>
              <div class="item">
                <el-form-item label="店铺:" prop="dian_id">
                  <el-select v-model="form.dian_id" placeholder="请选择">
                    <el-option v-for="item in shopList" :key="item.id" :label="item.dian_name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="item">
                <el-form-item label="喜报单数:" prop="">
                  <el-input v-model="form.xibao" placeholder="请输入"></el-input>
                </el-form-item>
              </div>
              <div class="item">
                <el-form-item label="组别:" prop="">
                  <el-input v-model="form.zubie" placeholder="请输入"></el-input>
                </el-form-item>
              </div>
              <div class="item">
                <el-form-item label="成交价格:" prop="price">
                  <el-input v-model="form.price" placeholder="请输入(元)"></el-input>
                </el-form-item>
              </div>
              <div class="item">
                <el-form-item label="收佣:" prop="yong_price">
                  <el-input v-model="form.yong_price" placeholder="请输入(元)"></el-input>
                </el-form-item>
              </div>
              <div class="item">
                <el-form-item label="成交日期:" prop="time">
                  <div class="time">
                    <el-date-picker @change="changeTime" v-model="form.time" type="date" value-format="timestamp"
                      placeholder="选择日期">
                    </el-date-picker>
                  </div>
                </el-form-item>
              </div>
              <div class="item">
                <el-form-item label="资料是否齐全:" prop="ziliao">
                  <el-select v-model="form.ziliao" placeholder="请选择">
                    <el-option v-for="item in materialList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
            <div class="item">
              <el-form-item label="上传资料:" prop="">
                <div class="">
                  <el-upload action="https://xin.xinyoujudichan.com/api/common/upload" list-type="picture-card"
                    :on-success="handlePictureCardPreview" :on-remove="handleRemove">
                    <i class="el-icon-plus"></i>
                  </el-upload>
                </div>
              </el-form-item>
            </div>
            <div class="item">
              <el-form-item label="备注:" prop="beizhu">
                <el-input type="textarea" :rows="4" placeholder="请输入备注内容" v-model="form.beizhu">
                </el-input>
              </el-form-item>
            </div>
            <div class="sign-flex sign-gray" v-for="(item, index) in chengjiao" :key="index + 'chengjiao'">
              <div class="">
                <el-form-item label="成交方:">
                  <el-select filterable remote :remote-method="remoteMethod"
                    @change="changeXin($event, 'chengjiao', index)" v-model="item.user_id" placeholder="请选择">
                    <el-option v-for="item in userList" :key="item.user_id" :label="item.name" :value="item.user_id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="">
                <el-form-item label="比例:">
                  <el-input @input="changeBi($event,'chengjiao',index)" class="w200" v-model="item.bi" placeholder="请输入" maxlength="3" max="100"></el-input>
                </el-form-item>
              </div>
              <div class="">
                <el-form-item label="成交方业绩:">
                  <el-input class="w200" v-model="item.price" placeholder="请输入"></el-input>
                </el-form-item>
              </div>
              <div class="sign-gray-btn m-l-15">
                <img v-if="index == 0" @click="addItem('chengjiao')" class="icon"
                  src="../assets/image/personage/add1.png">
                <img v-else @click="deleItem('chengjiao', index)" class="icon" src="../assets/image/personage/dele.png"
                  alt="">
              </div>
            </div>
            <div class="sign-flex sign-gray" v-for="(item, index) in xinxi" :key="index + 'xinxi'">
              <div class="">
                <el-form-item label="信息方:">
                  <el-select filterable remote :remote-method="remoteMethod" @change="changeXin($event, 'xinxi', index)" v-model="item.user_id" placeholder="请选择">
                    <el-option v-for="item in userList" :key="item.user_id" :label="item.name" :value="item.user_id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="">
                <el-form-item label="比例:">
                  <el-input @input="changeBi($event,'xinxi',index)" maxlength="3" max="100" class="w200" v-model="item.bi" placeholder="请输入"></el-input>
                </el-form-item>
              </div>
              <div class="">
                <el-form-item label="信息方业绩:">
                  <el-input class="w200" v-model="item.price" placeholder="请输入"></el-input>
                </el-form-item>
              </div>
              <div class="sign-gray-btn m-l-15">
                <img v-if="index == 0" @click="addItem('xinxi')" class="icon" src="../assets/image/personage/add1.png">
                <img v-else @click="deleItem('xinxi', index)" class="icon" src="../assets/image/personage/dele.png" alt="">
              </div>
            </div>
            <div class="sign-flex sign-gray" v-for="(item, index) in weihu" :key="index + 'weihu'">
              <div class="">
                <el-form-item label="维护方:">
                  <el-select filterable remote :remote-method="remoteMethod" @change="changeXin($event, 'weihu', index)" v-model="item.user_id" placeholder="请选择">
                    <el-option v-for="item in userList" :key="item.user_id" :label="item.name" :value="item.user_id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="">
                <el-form-item label="比例:">
                  <el-input class="w200" v-model="item.bi" placeholder="请输入" @input="changeBi($event,'weihu',index)" maxlength="3" max="100"></el-input>
                </el-form-item>
              </div>
              <div class="">
                <el-form-item label="维护方业绩:">
                  <el-input class="w200" v-model="item.price" placeholder="请输入"></el-input>
                </el-form-item>
              </div>
              <div class="sign-gray-btn m-l-15">
                <img v-if="index == 0" @click="addItem('weihu')" class="icon" src="../assets/image/personage/add1.png">
                <img v-else @click="deleItem('weihu', index)" class="icon" src="../assets/image/personage/dele.png" alt="">
              </div>
            </div>
            <div class="sign-flex sign-gray" v-for="(item, index) in img" :key="index + 'img'">
              <div class="">
                <el-form-item label="图片方:">
                  <el-select filterable remote :remote-method="remoteMethod" @change="changeXin($event, 'img', index)" v-model="item.user_id" placeholder="请选择">
                    <el-option v-for="item in userList" :key="item.user_id" :label="item.name" :value="item.user_id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="">
                <el-form-item label="比例:">
                  <el-input class="w200" v-model="item.bi" placeholder="请输入" @input="changeBi($event,'img',index)" maxlength="3" max="100"></el-input>
                </el-form-item>
              </div>
              <div class="">
                <el-form-item label="图片方业绩:">
                  <el-input class="w200" v-model="item.price" placeholder="请输入"></el-input>
                </el-form-item>
              </div>
              <div class="sign-gray-btn m-l-15">
                <img v-if="index == 0" @click="addItem('img')" class="icon" src="../assets/image/personage/add1.png">
                <img v-else @click="deleItem('img', index)" class="icon" src="../assets/image/personage/dele.png" alt="">
              </div>
            </div>
            <div class="sign-flex sign-gray" v-for="(item, index) in yaoshi" :key="index + 'yaoshi'">
              <div class="">
                <el-form-item label="钥匙方:">
                  <el-select filterable remote :remote-method="remoteMethod" @change="changeXin($event, 'yaoshi', index)" v-model="item.user_id" placeholder="请选择">
                    <el-option v-for="item in userList" :key="item.user_id" :label="item.name" :value="item.user_id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="">
                <el-form-item label="比例:">
                  <el-input class="w200" v-model="item.bi" placeholder="请输入" @input="changeBi($event,'yaoshi',index)" maxlength="3" max="100"></el-input>
                </el-form-item>
              </div>
              <div class="">
                <el-form-item label="钥匙方业绩:">
                  <el-input class="w200" v-model="item.price" placeholder="请输入"></el-input>
                </el-form-item>
              </div>
              <div class="sign-gray-btn m-l-15">
                <img v-if="index == 0" @click="addItem('yaoshi')" class="icon" src="../assets/image/personage/add1.png">
                <img v-else @click="deleItem('yaoshi', index)" class="icon" src="../assets/image/personage/dele.png"
                  alt="">
              </div>
            </div>
            <div class="sign-flex sign-gray" v-for="(item, index) in weituo" :key="index + 'weituo'">
              <div class="">
                <el-form-item label="委托方:">
                  <el-select filterable remote :remote-method="remoteMethod" @change="changeXin($event, 'weituo', index)" v-model="item.user_id" placeholder="请选择">
                    <el-option v-for="item in userList" :key="item.user_id" :label="item.name" :value="item.user_id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="">
                <el-form-item label="比例:">
                  <el-input class="w200" v-model="item.bi" placeholder="请输入" @input="changeBi($event,'weituo',index)" maxlength="3" max="100"></el-input>
                </el-form-item>
              </div>
              <div class="">
                <el-form-item label="委托方业绩:">
                  <el-input class="w200" v-model="item.price" placeholder="请输入"></el-input>
                </el-form-item>
              </div>
              <div class="sign-gray-btn m-l-15">
                <img v-if="index == 0" @click="addItem('weituo')" class="icon" src="../assets/image/personage/add1.png">
                <img v-else @click="deleItem('weituo', index)" class="icon" src="../assets/image/personage/dele.png"
                  alt="">
              </div>
            </div>
            <div class="sign-flex sign-gray" v-for="(item, index) in qita" :key="index + 'qita'">
              <div class="">
                <el-form-item label="其他方:">
                  <el-select filterable remote :remote-method="remoteMethod" @change="changeXin($event, 'qita', index)" v-model="item.user_id" placeholder="请选择">
                    <el-option v-for="item in userList" :key="item.user_id" :label="item.name" :value="item.user_id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="">
                <el-form-item label="比例:">
                  <el-input class="w200" v-model="item.bi" placeholder="请输入" @input="changeBi($event,'qita',index)" maxlength="3" max="100"></el-input>
                </el-form-item>
              </div>
              <div class="">
                <el-form-item label="其他方业绩:">
                  <el-input class="w200" v-model="item.price" placeholder="请输入"></el-input>
                </el-form-item>
              </div>
              <div class="sign-gray-btn m-l-15">
                <img v-if="index == 0" @click="addItem('qita')" class="icon" src="../assets/image/personage/add1.png">
                <img v-else @click="deleItem('qita', index)" class="icon" src="../assets/image/personage/dele.png" alt="">
              </div>
            </div>
          </el-form>
          <div class="interview_main_footer center">
            <el-button @click="submit('form')">提交</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      content: "", //规则
      form: {
        id: null,
        type: "", //1=线上2=线下
        lou: "", //小区楼栋
        dian_id: "", //门店id
        contract_number: "", //合同编号
        price: "", //成交价格
        yong_price: "", //佣金金额
        jiao_time: "", //成交时间 时间戳
        time: "", //成交时间 时间戳秒
        ziliao: "", //资料 是/否
        ziliao_image: "", //资料图片 多个用逗号隔开
        beizhu: "", //备注
        chengjiao: "", //成交方
        xinxi: "", //信息方
        weihu: "", //维护方 同上
        img: "", //图片方 同上
        weituo: "", //委托方 同上
        yaoshi: "", //钥匙方 同上
        qita: "", //其他方 同上
        hao: "", //小区几号楼
        danyuan: "", //小区几单元
        doorplate: "", //小区门牌号
        direction: "", //小区朝向
        zubie: '',
        xibao: ''
      },
      ziliao_image: [], //资料图片
      chengjiao: [
        {
          user_id: "",
          user_name: "",
          bi: "",
          price: "",
        },
      ], //成交方
      xinxi: [
        {
          user_id: "",
          user_name: "",
          bi: "",
          price: "",
        },
      ], //信息方
      weihu: [
        {
          user_id: "",
          user_name: "",
          bi: "",
          price: "",
        },
      ], //维护方
      img: [
        {
          user_id: "",
          user_name: "",
          bi: "",
          price: "",
        },
      ], //图片方
      weituo: [
        {
          user_id: "",
          user_name: "",
          bi: "",
          price: "",
        },
      ], //委托方
      yaoshi: [
        {
          user_id: "",
          user_name: "",
          bi: "",
          price: "",
        },
      ], //钥匙方
      qita: [
        {
          user_id: "",
          user_name: "",
          bi: "",
          price: "",
        },
      ], //其他方
      rules: {
        name: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        contract_number: [
          { required: true, message: "请输入合同编号", trigger: "blur" },
        ],
        beizhu: [
          { required: true, message: "请输入备注内容", trigger: "blur" },
        ],
        dian_id: [{ required: true, message: "请选择店铺", trigger: "change" }],
        price: [{ required: true, message: "请输入成交价格", trigger: "blur" }],
        yong_price: [
          { required: true, message: "请输入收佣", trigger: "blur" },
        ],
        time: [
          {
            type: "date",
            required: true,
            message: "请选择成交日期",
            trigger: "change",
          },
        ],
        ziliao: [
          { required: true, message: "请选择资料是否齐全", trigger: "change" },
        ],
      },
      materialList: [
        {
          value: "是",
          label: "是",
        },
        {
          value: "否",
          label: "否",
        },
      ], //资料是否齐全
      shopList: [], //店铺列表
      userList: [], //用户列表
    };
  },
  methods: {
    //输入比例
    changeBi (e,from,index) {
      if (e) {
        if (!this[from][index].user_name) {
          this[from][index].bi = ''
          return this.$message({
            message: "请选择姓名",
            type: "warning",
          });
        }
        this[from][index].price = Number(this.form.yong_price * (e / 100)).toFixed(2)
      }
    },
    //
    remoteMethod (e) {
      this.getUserList(e)
    },
    //删除数据
    deleItem(from, index) {
      this[from].splice(index, 1)
    },
    //添加数据
    addItem(from) {
      this[from].push({
        user_id: "",
        user_name: "",
        bi: "",
        price: "",
      })
    },
    changeXin(e, name, index) {
      this.userList.forEach((item) => {
        if (item.user_id == e) {
          this[name][index].user_id = item.user_id;
          this[name][index].user_name = item.name;
        }
      });
      this.getUserList('')
    },
    //提交
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.form.danyuan || !this.form.hao || !this.form.doorplate || !this.form.direction) return this.$message({
            message: "请完善小区楼栋",
            type: "warning",
          });
          this.form.lou =
            this.form.hao +
            "," +
            this.form.danyuan +
            "," +
            this.form.doorplate +
            "," +
            this.form.direction;
          if (!this.ziliao_image.length)
            return this.$message({
              message: "请上传资料照片",
              type: "warning",
            });
          this.form.chengjiao = JSON.stringify(this.chengjiao);
          this.form.xinxi = JSON.stringify(this.xinxi);
          this.form.weihu = JSON.stringify(this.weihu);
          this.form.img = JSON.stringify(this.img);
          this.form.weituo = JSON.stringify(this.weituo);
          this.form.yaoshi = JSON.stringify(this.yaoshi);
          this.form.qita = JSON.stringify(this.qita);
          this.form.ziliao_image = this.ziliao_image.join(",");

          let chengjiaoNumber = this.chengjiao.reduce((accumulator, currentItem) => accumulator + Number(currentItem.bi), 0);

          let xinxiNumber = this.xinxi.reduce((accumulator, currentItem) => accumulator + Number(currentItem.bi), 0);

          let weihuNumber = this.weihu.reduce((accumulator, currentItem) => accumulator + Number(currentItem.bi), 0);

          let imgNumber = this.img.reduce((accumulator, currentItem) => accumulator + Number(currentItem.bi), 0);

          let weituoNumber = this.weituo.reduce((accumulator, currentItem) => accumulator + Number(currentItem.bi), 0);

          let yaoshiNumber = this.yaoshi.reduce((accumulator, currentItem) => accumulator + Number(currentItem.bi), 0);

          let qitaNumber = this.qita.reduce((accumulator, currentItem) => accumulator + Number(currentItem.bi), 0);

          if ((chengjiaoNumber + xinxiNumber + weihuNumber + imgNumber + weituoNumber + yaoshiNumber + qitaNumber) > 100) return this.$message({
              message: "所有方的比例不能超过100",
              type: "warning",
            });
          this.$axios.baoGao(this.form).then((res) => {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.dialogVisible = false;
          });
        } else {
          return false;
        }
      });
    },
    //选择日期
    changeTime(e) {
      this.form.jiao_time = e / 1000;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.ziliao_image.push(file.data.fullurl);
    },
    //关闭
    close() {
      this.dialogVisible = false;
    },
    //打开
    open(id, fang_type, fang_types) {
      //
      this.form.id = id;

      this.$axios
        .SignAContractDetail({ id, fang_type, fang_types })
        .then((res) => {
          this.dialogVisible = true;

          this.form.type = res.data.type;
          this.form.price = res.data.price;
          this.form.yong_price = res.data.zong_money
          this.form.contract_number = res.data.number;
          this.$axios.houseZuDetail({ id: res.data.fang_id }).then(res => {
           
            let loudong = res.data.xiaoqu_loudongs ? res.data.xiaoqu_loudongs.split(',') : []
            this.form.hao = loudong[0]
            this.form.danyuan = loudong[1]
            this.form.doorplate = loudong[2]

            this.form.direction = res.data.fang_chaoxiang
          })
        });
      this.$axios.menDian().then((res) => {
        this.shopList = res.data;
      });
      this.getUserList('')
    },
    //获取用户列表
    getUserList (name) {
      this.$axios.userList({name}).then((res) => {
        this.userList = res.data;
      });
    }
  },
};
</script>

<style lang="less" scoped>
.sign-gray-btn {
  .icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}

.sign-gray {
  background: #f5f5f5;
  padding: 20px 0px;
  margin-bottom: 22px;

  /deep/ .el-form-item {
    margin-bottom: 0;
  }
}

.sign-flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .item {
    width: 50%;

    /deep/ .el-select,
    .time /deep/ .el-input {
      width: 100%;
    }
  }
}

/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0 !important;

  /deep/ .el-input__inner {
    border: 1px solid #ccc;
    border-radius: 0;
  }

  /deep/ .el-date-editor.el-input {
    width: 400px;
  }

  /deep/ .el-input {
    width: 400px;
  }

  /deep/ .w96.el-input {
    width: 92.5px;
  }

  /deep/ .w200.el-input {
    width: 200px;
  }
}

/deep/ .el-dialog {
  border-radius: 8px;
  overflow: hidden;
}

.interview_main_footer {
  padding: 30px 0 40px 0;

  /deep/ .el-button {
    width: 480px;
    height: 56px;
    color: #fff;
    font-size: 18px;
    background: #3273f6;
    border-radius: 28px;
  }
}

.plot-input {
  /deep/ .el-input {
    width: 90px;
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

/deep/ .el-textarea {
  width: 400px;
}</style>
